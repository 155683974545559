import { useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import useSWR from 'swr'

import { Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferencesCache, userQuery } from '@/api/AccountService'
import { useAuthStore } from '@/store/authStore'

export function useDisplayedCurrency() {
  const { delegatorId } = useAuthStore((state) => state.permissions)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences, delegatorId],
    fetchCurrentPreferencesCache(database!)
  )

  const [displayedCurrency, setDisplayedCurrency] = useState<Currency>(preferences?.baseCurrency ?? Currency.USD)

  const handleDisplayedCurrency = (option: SingleValue<{ label: Currency; value: Currency }>) => {
    if (option !== null) {
      setDisplayedCurrency(option.value)
    }
  }

  useEffect(() => {
    setDisplayedCurrency(preferences.baseCurrency ?? Currency.USD)
  }, [preferences.baseCurrency])

  return {
    displayedCurrency,
    handleDisplayedCurrency
  }
}
